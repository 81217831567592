
import React from 'react';
import HavingsWantsForm from './components/HavingsWantsForm';
import { CssBaseline, Container } from '@material-ui/core';

function App() {
  return (
    <div>
      {/* <CssBaseline /> */}
      <Container maxWidth="sm">
        <h1>My App</h1>
        <HavingsWantsForm />
      </Container>
    </div>
  );
}

export default App;