import React from 'react';
import { Button, Box } from '@mui/material';
import HavingsSelect from '../HavingsSelect';
import WantsSelect from '../WantsSelect';
import { useForm } from './useForm';
import useStyles from './styles';
import { saveSelections } from '../../api';
import Swal from 'sweetalert2';

const HavingsWantsForm = () => {
  const classes = useStyles();
  const { formState, updateField, resetForm } = useForm();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!formState.having || !formState.want) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select both having and want options',
      });
      return;
    }

    Swal.fire({
        title: 'Processing...',
        html: 'Please wait...',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
        },
    });

     // Convert user's input to title case
     let having = formState.having.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
     let want = formState.want.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
     console.log('Selected have:', having);
     console.log('Selected want:', want);

     const payload = {
       userId: '1', // Replace with actual user ID logic
       have: having,
       want: want,
     };

    saveSelections(payload)
      .then((res) => {
        console.log(res);
        resetForm();
        Swal.fire({
          icon: 'success',
          title: 'Your selections have been saved',
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'There was an error saving your selections. Please try again.',
        });
      });
  };

  return (
    <Box component="form" onSubmit={handleFormSubmit} className={classes.root}>
      <HavingsSelect
        value={formState.having}
        onChange={(value) => updateField('having', value)}
      />
      <WantsSelect
        value={formState.want}
        onChange={(value) => updateField('want', value)}
      />
      <Button type="submit" variant="contained" className={classes.button}>
        Submit
      </Button>
    </Box>
  );
};

export default HavingsWantsForm;
