// src/components/HavingsWantsForm/useForm.js

import { useState } from 'react';

export const useForm = () => {
  const [formState, setFormState] = useState({
    having: '',
    want: '',
  });

  const updateField = (fieldName, value) => {
    setFormState((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const resetForm = () => {
    updateField('having', '');
    updateField('want', '');
  };

  return { formState, updateField, resetForm };
};


export default useForm;
