import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { getHaves } from '../../api';
import { useStyles } from './styles';

const HavingsSelect = ({ onChange, value }) => {
  const classes = useStyles();
  const [havingsOptions, setHavingsOptions] = useState([]);

  useEffect(() => {
    const fetchHavingsOptions = async () => {
      const response = await getHaves();
      if (response && response.status === 'success') {
        const havingsData = response.data.map(item => item.have);
        setHavingsOptions(havingsData);
      }
    };

    fetchHavingsOptions();
  }, []);

  return (
    <Autocomplete
      options={havingsOptions}
      freeSolo
      className={classes.autocomplete}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        onChange(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="You have:" />}
    />
  );
};

export default HavingsSelect;
