import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { getWants } from '../../api';
import { useStyles } from './styles';

const WantsSelect = ({ onChange, value }) => {
  const classes = useStyles();
  const [wantsOptions, setWantsOptions] = useState([]);

  useEffect(() => {
    const fetchWantsOptions = async () => {
      const response = await getWants();
      console.log("fetchWantsOptions response",response);
      if (response && response.status === 'success') {
        console.log("fetchWantsOptions",response.data);
        setWantsOptions(response.data.map(item => item.want)); // Extract the 'want' property
      }
    };

    fetchWantsOptions();
  }, []);

  return (
    <Autocomplete
      options={wantsOptions}
      freeSolo
      className={classes.autocomplete}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        onChange(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="You want:" />}
    />
  );
};

export default WantsSelect;
