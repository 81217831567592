import axios from 'axios';
import { SELECTIONS_API_PATH } from '../constants/apiPaths';

const API_URL = process.env.REACT_APP_API_URL;

// export const saveSelections = async (userId, have, want) => {
//   const url = `${API_URL}${SELECTIONS_API_PATH}`;
//   try {
//     const response = await axios.post(url, {
//       userId,
//       have,
//       want,
//     });
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const saveSelections = async (data) => {
    const url = `${API_URL}${SELECTIONS_API_PATH}`;
    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getHaves = async () => {
  const url = `${API_URL}${SELECTIONS_API_PATH}?type=getHaves`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getWants = async () => {
  const url = `${API_URL}${SELECTIONS_API_PATH}?type=getWants`;
  try {
    const response = await axios.get(url);
    console.log("getWants", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};