import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: `${theme.spacing(1)}px !important`,
//       width: '25ch',
//     },
//   },
//   button: {
//     '&': {
//       margin: theme.spacing(2),
//     },
//   },
}));

export default useStyles;